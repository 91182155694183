import { Box, Button, FileInput, Group, rem } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconEdit, IconFileCv } from "@tabler/icons-react";
import { useState } from "react";
import { TblUploadImage } from "../../../model/Tbluploadimage";
import { uploadImage } from "../../../api/ApiUploadimage"; // Sử dụng API uploadImage thay vì createAppointment

const Upload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [imageUrl, setImageUrl] = useState<string | null>(null); // Trạng thái để lưu URL hình ảnh
  const icon = (
    <IconFileCv style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
  );

  const [file, setFile] = useState<File | null>(null);

  // Initialize the form with initial entity structure
  const form = useForm<TblUploadImage>({
    initialValues: {
      image: null, // Set initial value to null for image field
    },
    validate: {
      image: (value) => (value ? null : "File không được để trống"),
    },
  });

  const handleChangeImage = (file: File | null) => {
    setFile(file); // Update the file state directly
    form.setFieldValue("image", file); // Set the file in the form state
  };

  // Handle form submission
  const handleSubmit = async (dataSubmit: TblUploadImage) => {
    if (!file) {
      setError("File không được để trống");
      return;
    }

    console.log(dataSubmit); // Check the data before submitting

    try {
      setLoading(true);

      // Call the API to upload the image directly (no need for FormData)
      const response = await uploadImage(file); // Pass the file directly to the uploadImage API

      if (response?.success) {
        // Handle success (e.g., show a success message, refresh the list, etc.)
        setImageUrl(response.data); // Lưu URL hình ảnh vào trạng thái
        alert("Image uploaded successfully!");
      } else {
        // If the server responds with an error message
        setError("Failed to upload the image. Please try again.");
      }
    } catch (err) {
      setError("An error occurred while uploading the image.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      className="flex-none"
      component="form"
      miw={500}
      maw={500}
      mx={"sm"}
      mb={"md"}
      onSubmit={form.onSubmit(handleSubmit)}
    >
      <Group grow wrap="nowrap" mt="xs" gap={"lg"}>
        <FileInput
          leftSection={icon}
          label="Hình ảnh"
          placeholder="Choose image"
          withAsterisk
          accept="image/png,image/jpeg"
          clearable
          mt="md"
          value={form.values.image} // Bind form field value (File)
          onChange={handleChangeImage} // Handle file change
        />
      </Group>

      {error && <div style={{ color: "red" }}>{error}</div>}

      <Group justify="start" mt="md">
        <Button
          type="submit"
          color="blue"
          leftSection={<IconEdit size={18} />}
          loading={loading}
        >
          UpDate
        </Button>
      </Group>

      {/* Hiển thị URL hình ảnh nếu có */}
      {imageUrl && (
        <div style={{ marginTop: "20px" }}>
          <h3>Hình ảnh đã tải lên:</h3>
          <img
            src={imageUrl}
            alt="Uploaded"
            style={{ maxWidth: "100%", maxHeight: "400px" }}
          />
          <p>{imageUrl}</p> {/* Hiển thị URL nếu cần */}
        </div>
      )}
    </Box>
  );
};

export default Upload;
