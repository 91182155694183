import { AxiosResponse } from "axios";
import { API_ROUTE } from "../const/apiRoute";
import { HanderResponse } from "../_base/helper/FunctionHelper";
import { api } from "../library/axios";
import { NotificationExtension } from "../_base/extension/NotificationExtension";
import { isNullOrUndefined } from "../_base/extension/StringExtension";

export const uploadImage = async (file: File): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append("image", file); // Append the image file to FormData

    const response: AxiosResponse = await api.post(
      API_ROUTE.UPLOAD_IMAGE, // Đổi thành API cho việc tải lên hình ảnh
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response?.data?.success) {
      NotificationExtension.Success("Tải lên hình ảnh thành công!");
    } else {
      NotificationExtension.Fails("Tải lên hình ảnh thất bại!");
    }

    return response.data;
  } catch (error) {
    HanderResponse(error); // Xử lý lỗi từ API nếu có
  }
};
